<template>
  <div class="absolute-right">
    <q-drawer
      v-model="show"
      side="right"
      :width="300"
      elevated
    >
      <q-list no-border>
        <q-item>
          <m-select-query
            v-model="parameters.origin"
            :placeholder="$t('input.origin')"
            :label="$t('going_from')"
            icon="place"
            stack-label
            :query="autocomplete"
          />
        </q-item>
        <q-item>
          <m-select-query
            v-model="parameters.destination"
            :placeholder="$t('input.destination')"
            :label="$t('going_to')"
            icon="place"
            stack-label
            :query="autocomplete"
          />
        </q-item>
        <q-item>
          <date-field
            v-model="parameters.depart"
            :min="minDate"
            :max="maxDate"
            format="ccc dd LLL yy"
            :label="$t('date.travel')"
            use-dropdown
          />

          <time-field
            v-model="parameters.depart"
            :min="minDate"
            label=""
            use-dropdown
          />
        </q-item>

        <q-item>
          <q-btn
            big
            color="primary"
            @click="$store.dispatch('smartsearch/initialQuery')"
          >
            {{ $t('search_again') }}
          </q-btn>
        </q-item>
      </q-list>
    </q-drawer>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { query } from 'api/places'
import { Dialog } from 'quasar'
import date from 'utils/date-time'
import loading from 'utils/loading'
import timeField from 'components/MFields/time'
import dateField from '@/MFields/date'
import store from 'store'

export default {
  name: 'SmartSearchSidebar',
  components: {
    timeField,
    dateField
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile
    }
  },
  computed: {
    ...mapGetters({
      parameters: 'smartsearch/parameters',
      loading: 'smartsearch/loading',
      errors: 'smartsearch/errors'
    }),
    minDate () {
      return date.addToDate(new Date(), { hours: 5 })
    },
    maxDate () {
      return this.parameters.return
    }
  },

  watch: {
    loading (value, old) {
      if (value === true) {
        const partner = store.getters.partner
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: partner.slug,
          spinnerColor: partner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        this.$router.push({ name: 'smart-search-results' })
      }
    },

    errors (value, old) {
      if (value) {
        Dialog.create({
          title: value.title,
          message: value.message
        })
      }
    }

  },

  validations: {
    parameters: {
      origin: { required },
      destination: { required }
    }
  },

  methods: {
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  width 80vw
  max-width 600px

.q-item > *
  width 100%
</style>
