<template>
  <q-card
    v-ripple="result"
    :class="{'cursor-pointer': result}"
    class="content-card text-primary"
    @click="navigateToNextScreen"
  >
    <q-card-section class="row justify-between">
      <sub class="text-primary text-bold text-uppercase">{{ label }}</sub>
      <sub v-if="result === null" class="text-grey-7 col text-right">{{ $t('information_unavailable') }}</sub>
      <sub v-else-if="result" class="text-grey-7 text-left">{{ $t('estimated_cost') }} <br>{{ canBookBus || label === 'carclub' ? $t('from') : '' }}</sub>
    </q-card-section>
    <template v-if="result">
      <q-card-section class="row justify-betweeen">
        <div class="row modes">
          <div
            v-for="(item, i) in result.overview"
            :key="i"
            class="item"
          >
            <q-icon
              :name="icon(item)"
              :color="color(item)"
              :size="$q.platform.is.desktop ? '40px' : '30px'"
              class="col-12"
            />
            <div class="dots">
              <div class="dot" />
              <div class="dot" />
              <div class="dot" />
            </div>
          </div>
        </div>
        <div class="col text-right text-grey-7">
          <h4>
            {{ priceText }}
          </h4>
          {{ label === 'carclub' ? $t('per_hour') : '' }}
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section class="row justify-between">
        <div v-if="result.duration" class="duration col-8 text-body2">
          {{ durationText }} {{ result.duration.text }}
        </div>
        <div class="tags col">
          <q-badge
            v-for="(tag, i) in result.tags"
            :key="i"
            color="blue-2"
            text-color="blue-10"
            class="tag text-capitalize"
          >
            {{ tag }}
          </q-badge>
        </div>
      </q-card-section>
    </template>
    <template v-else-if="result !== null">
      <q-card-section>
        <div class="loader">
          <q-linear-progress indeterminate />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <span class="text-body2 text-italic">{{ $t('loading.searching.smartsearch_individual') }}</span>
      </q-card-section>
    </template>
  </q-card>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      required: true,
      type: String
    },
    result: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      parameters: 'smartsearch/parameters',
      partner: 'partner',
      user: 'user'
    }),
    label () {
      return this.type === 'ridehailing' ? 'taxi' : this.type
    },
    durationText () {
      if (this.type === 'walking') {
        return this.$t('smartsearch.will_take.walking')
      } else if (this.type === 'bicycle') {
        return this.$t('smartsearch.will_take.cycling')
      } else if (this.type === 'bus') {
        return this.$t('smartsearch.will_take.bus')
      } else if (this.type === 'carclub') {
        return this.$t('smartsearch.will_take.carclub')
      } else if (this.type === 'train' || this.type === 'rail') {
        return this.$t('smartsearch.will_take.train')
      } else if (this.type === 'ridehailing') {
        return this.$t('smartsearch.will_take.taxi')
      } else if (this.type === 'flight') {
        return this.$t('smartsearch.will_take.flight')
      } else if (this.type === 'tube') {
        return this.$t('smartsearch.will_take.tube')
      } else if (this.type === 'eurostar') {
        return this.$t('smartsearch.will_take.eurostar')
      } else if (this.type === 'tram') {
        return this.$t('smartsearch.will_take.tram')
      } else if (this.type === 'rental') {
        return this.$t('smartsearch.will_take.rental')
      } else if (this.type === 'driving') {
        return this.$t('smartsearch.will_take.driving')
      } else {
        return this.$t('smartsearch.will_take.default')
      }
    },
    canBookBus () {
      return ['gohi'].includes(this.partner.slug) && this.label === 'bus'
    },
    priceText () {
      if (this.canBookBus) return '£3.90'
      if (['bus', 'tram', 'tube'].includes(this.label)) return '- - -'
      if (this.result?.price?.text) return this.result.price.text
      return this.$t('price_free')
    }
  },
  mounted () {
    console.log(this.result)
  },
  methods: {
    icon (type) {
      return this.partner.smartSearch[type].icon
    },
    color (type) {
      return this.partner.smartSearch[type].color
    },
    showContextual () {
      this.$router.push({
        hash: '#/contextual',
        query: { token: this.token, type: this.type }
      })
    },
    goToResults () {
      const bookable = ['rail', 'bus', 'flight', 'tram', 'rental', 'ridehailing', 'srf']

      if (bookable.includes(this.type)) {
        this.$router.push({
          name: this.partner.smartSearch[this.type].route
        })
      } else if (this.type === 'carclub') {
        if (this.user.has_carclub) {
          this.$router.push({ name: 'ondemand-carclub-results' })
        } else {
          this.$router.push({ hash: '#/user-settings/carclub' })
        }
      } else {
        const origin = this.parameters.origin.label
        const destination = this.parameters.destination.label
        let travelmode

        switch (this.type) {
        case 'driving': travelmode = 'driving'; break
        case 'tube': travelmode = 'transit'; break
        case 'walking': travelmode = 'walking'; break
        case 'bicycle': travelmode = 'bicycling'; break
        }

        openURL(`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=${travelmode}`)
      }
    },
    navigateToNextScreen () {
      if (!this.result) return
      if (this.$q.platform.is.desktop) this.showContextual()
      else this.goToResults()
    }
  }
}
</script>
<style lang="stylus" scoped>
.content-card
  min-width 456px

@media only screen and (max-width: 456px)
  .content-card
    min-width inherit

.loader
  min-height 56px
  height 56px
  max-height 56px
  display flex
  justify-items center
  align-items center
.q-card
  position relative
  background-color #fff
  > *
    color #333

.price
  font-size 1.5em
  margin 8px 0

.item
  display flex
  justify-content flex-start

.dots
  display flex
  margin: auto 0;
  margin-left 8px
  @media (min-width: 768px)
    margin-left 16px

.dot
  height 5px
  width 5px
  background #eee
  border-radius 50%
  margin-right 6px
  @media (min-width: 768px)
    height 10px
    width 10px

.duration
  flex-basis 65%

.tags
  display flex
  justify-content flex-end
  align-items flex-start
  flex-basis 35%

.tag
  margin-left 10px
</style>
