<template>
  <q-item class="column" @click.native="onClick">
    <div class="row justify-between">
      <div class="col-4 text-capitalize text-bold ">
        <span>
          {{ result.content_type | contentType }}
        </span>
      </div>
      <div class="col-4 text-right text-bold">
        <span>
          {{ priceText }}
        </span>
      </div>
    </div>
    <div class="row justify-between" :class="isActive ? 'q-mt-sm' : 'q-my-sm'">
      <div class="col">
        <q-icon
          :name="icon(result.content_type)"
          :color="color(result.content_type)"
          :size="$q.platform.is.desktop ? '40px' : '30px'"
        />
      </div>
    </div>
    <div v-if="isActive" class="row justify-end q-mb-sm">
      <div class="text-right instructions column justify-end">
        {{ $t('smartsearch.get_directions', { action: $q.platform.is.mobile ? 'Tap' : 'Click' }) }}
      </div>
    </div>
    <div class="row justify-between">
      <div class="col">
        <span class="q-mr-sm text-primary">
          {{ result.duration.value | duration }}
        </span>
        <span v-if="active">
          ({{ result.distance.miles | distance }} active miles)
        </span>
      </div>
      <div class="col-4 text-right text-bold">
        <span class="q-mr-sm text-primary">
          {{ journeyTime.now | time }} - {{ journeyTime.then | time }}
        </span>
      </div>
    </div>
  </q-item>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
import { Duration } from 'luxon'
import dt from 'utils/date-time'
export default {
  name: 'SmartSearchResultItem',
  filters: {
    contentType (value) {
      switch (value) {
      case 'bikehire':
        return 'bike hire'
      case 'carclub':
        return 'car club'
      case 'ridehailing':
        return 'taxi'
      case 'bicycle':
        return 'own bike'
      default:
        return value
      }
    },
    distance (value) {
      return Math.round(value * 10) / 10
    },
    duration (value) {
      const duration = Duration.fromObject({ seconds: value })
      return duration.toFormat("hh'h ' mm'm")
    },
    time (val) {
      return dt.toCivilTime(val)
    }
  },
  props: {
    result: {
      type: Object
    },
    active: {
      type: Boolean,
      default: true
    },
    type: String
  },
  data () {
    return {
      bookable: ['rail', 'bus', 'flight', 'tram', 'rental', 'ridehailing', 'train']
    }
  },
  computed: {
    ...mapGetters({
      parameters: 'smartsearch/parameters',
      partner: 'partner',
      user: 'user'
    }),
    journeyTime () {
      const now = dt.now()
      const then = dt.addToDate(now, { seconds: this.result?.duration?.value || 0 })

      return { now, then }
    },
    isActive () {
      const nonActives = ['carclub', 'drt'].concat(this.bookable)
      return !nonActives.includes(this.result.content_type)
    },
    priceText () {
      if (this.type === 'bus' && ['gohi'].includes(this.partner.slug)) return 'From £3.90'
      if (['bus', 'tram', 'tube'].includes(this.type)) return '- - -'
      if (this.result?.price?.text) return this.result.price.text
      return this.$t('price_free')
    }
  },
  methods: {
    icon (type) {
      console.log(type)
      return this.partner.smartSearch[type].icon
    },
    color (type) {
      return this.partner.smartSearch[type].color
    },
    showContextual () {
      this.$router.push({
        hash: '#/contextual',
        query: { token: this.token, type: this.result.content_type }
      })
    },
    goToResults () {
      const { content_type } = this.result
      if (this.bookable.includes(content_type)) {
        this.$router.push({
          name: this.partner.smartSearch[content_type].route
        })
      } else if (content_type === 'carclub') {
        if (this.user.has_carclub) {
          this.$router.push({ name: 'ondemand-carclub-results' })
        } else {
          this.$router.push({ hash: '#/user-settings/carclub' })
        }
      } else if (content_type === 'drt') {
        this.$router.push({ name: 'ondemand-drt', query: { smartsearch: true }, params: { origin: this.parameters.origin, destination: this.parameters.destination } })
      } else {
        const origin = this.parameters.origin.label === 'Current Location' ? this.parameters.origin.value : this.parameters.origin.label
        const destination = this.parameters.destination.label
        let travelmode

        switch (content_type) {
        case 'driving': travelmode = 'driving'; break
        case 'tube': travelmode = 'transit'; break
        case 'walking': travelmode = 'walking'; break
        case 'bicycle': travelmode = 'bicycling'; break
        }

        openURL(`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=${travelmode}`)
      }
    },
    onClick () {
      if (!this.result) return
      if (this.$q.platform.is.desktop) this.showContextual()
      else this.goToResults()
    }
  }
}
</script>

<style lang="stylus">
.type-connector
  background-color black
  width 50px
  height 2px
  margin 0 10px
  color: #666
.instructions
  opacity 0.55
  font-size 0.65em
</style>
