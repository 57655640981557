<template>
  <q-page :class="$q.platform.is.desktop && 'page bg-white column items-center no-wrap'" padding>
    <template v-if="$q.platform.is.desktop">
      <div class="code flex items-center justify-center" />
      <search-form class="form" v-bind="$attrs" />
    </template>
    <on-demand-form
      v-else
      :initial="initial"
      :mapper="placeMapper"
      :location-query="placeSearch"
      :user="user"
      :addresses="{recent, saved}"
      time
      no-return
      smart
      @submit="onSubmit"
    />
  </q-page>
</template>

<script type="text/javascript">
import date from 'utils/date-time'
const { addToDate, adjustDate } = date
import searchForm from './search-form'
import onDemandForm from '../ondemand/form/index.vue'
import { query as placeSearch } from 'api/places'
import { placeMapper } from '../ondemand/form/mappers'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import store from 'store'
import flightModel from 'pages/ondemand/flights/model'

export default {
  name: 'SmartSearch',
  components: {
    searchForm,
    onDemandForm
  },
  data () {
    return {
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: date.toCivilDateTime(date.addToDate(date.newDate(), { minutes: 5 })),
          inbound: ''
        },
        options: {
          adults: 1,
          children: 0,
          rooms: 1
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup',
      loading: 'smartsearch/loading',
      recent: 'addresses/recent',
      saved: 'addresses/saved'
    })
  },
  watch: {
    loading (value, old) {
      const partner = store.getters.partner
      if (value === true) {
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: partner.slug,
          spinnerColor: partner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (this.errors) {
          this.$q.dialog({
            title: this.$t('error.search_unsuccessful'),
            message: this.$t('error.try_later'),
            color: 'primary',
            ok: true,
            cancel: this.$t('leave_feedback')
          })
            .onCancel(() => {
              var win = window.open('https://mobilleo.com/resources/support/', '_blank')
              win.focus()
            })
        } else {
          this.$router.push({ name: 'smart-search-results' })
        }
      }
    },
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = {
            label: val.label,
            value: `${val.latitude},${val.longitude}`
          }
        }
      }
    }
  },
  created () {
    this.$store.dispatch('smartsearch/reset')
  },
  methods: {
    placeSearch,
    placeMapper,
    onSubmit (values) {
      const mapped = {
        ...values,
        depart: values.date.outbound
      }

      // Store details now so we can direct straight to results later
      Promise.all([
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...flightModel(),
            ...mapped,
            outbound: values.date.outbound,
            inbound: values.date.inbound,
            pickup: {
              location: {
                value: values.origin.value
              },
              date: values.date.outbound
            },
            dropoff: {
              location: {
                value: values.destination.value
              },
              date: addToDate(adjustDate(values.date.outbound, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }), { days: 1 })
            }
          }
        }),
        this.$store.dispatch('carClub/setTraveller', values.user),
        this.$store.dispatch('carClub/setRequester', values.requester),
        this.$store.dispatch('carClub/setSearchLocation', {
          label: values.origin.label,
          value: values.origin.value
        }),
        this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(values.date.outbound)),
        this.$store.dispatch('carClub/setSearchEndDateTime', addToDate(values.date.outbound, { hours: 2 })),
        this.$store.dispatch('rideHailing/setTraveller', values.user),
        this.$store.dispatch('rideHailing/setRequester', values.requester),
        this.$store.dispatch('rideHailing/setOrigin', {
          label: values.origin.label,
          value: values.origin.value
        }),
        this.$store.dispatch('rideHailing/setDestination', {
          label: values.destination.label,
          value: values.destination.value
        }),
        this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: addToDate(values.date.outbound, { minutes: 5 }) }),
        this.$store.dispatch('smartsearch/stash', mapped)
      ]).then(() => {
        this.$store.dispatch('smartsearch/initialQuery')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/cities/London.jpg')
  background-position: 0 60%;
  background-size: 100% 300%;
  text-overflow: clip;

.form
  margin-top -64px
</style>
