<template>
  <div class="column container">
    <q-card
      square
      class="bg-white"
    >
      <q-card-section :class="`bg-primary text-white text-uppercase`">
        {{ $t('where_are_you_going') }}
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-10">
            <m-select-query
              v-model="parameters.origin"
              :label="$t('going_from')"
              stack-label
              :placeholder="$t('choose_origin')"
              icon="place"
              :query="autocomplete"
              clearable
              smart-search
              @focus="onSmartSearchFocus('origin')"
            />
            <m-select-query
              v-model="parameters.destination"
              :label="$t('going_to')"
              stack-label
              :placeholder="$t('choose_destination')"
              icon="place"
              :query="autocomplete"
              clearable
              smart-search
              @focus="onSmartSearchFocus('destination')"
            />
          </div>
          <div class="col-2 text-center self-center">
            <q-btn
              flat
              class="icon"
              size="14px"
              icon="fa fa-sort-alt"
              @click="swap"
            />
          </div>
          <div v-if="!dateClicked" class="col-12" @click="onDateClick">
            <q-input :value="$t('leave_now')" :label="$t('date.travel')" />
          </div>
          <template v-else>
            <div class="col-6" @click="onDateClick">
              <date-field
                v-model="parameters.depart"
                :min="minDate"
                :label="$t('date.travel')"
              />
            </div>
            <div class="col-6">
              <time-field
                v-model="parameters.depart"
                :min="minDate"
                :label="$t('time.from')"
              />
            </div>
            <div
              v-if="returning"
              class="col-6"
            >
              <date-field
                v-model="parameters.return"
                :min="minReturnDate"
                :label="$t('date.return')"
              />
            </div>
            <div
              v-if="returning"
              class="col-6"
            >
              <time-field
                v-model="parameters.return"
                :min="minReturnDate"
                :label="$t('time.return')"
              />
            </div>
          </template>
        </div>
      </q-card-section>
    </q-card>
    <div class="row">
      <div class="col">
        <m-unified-users v-model="people" />
      </div>
    </div>
    <div
      class="row"
      style="padding: 8px"
    >
      <q-btn
        :disabled="$v.$invalid"
        size="lg"
        color="primary"
        class="full-width"
        @click="$store.dispatch('smartsearch/initialQuery')"
      >
        {{ $t('discover_travel_options') }}
      </q-btn>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { query } from 'api/places'
import date from 'utils/date-time'
import loading from 'utils/loading'
const { addToDate } = date
import timeField from 'components/MFields/time'
import dateField from '@/MFields/date'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import store from 'store'

export default {
  name: 'SmartSearchForm',
  components: {
    timeField,
    dateField
  },
  mixins: [ authentication, searchLocation ],
  data () {
    return ({
      dateClicked: false
    })
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng',
      parameters: 'smartsearch/parameters',
      loading: 'smartsearch/loading',
      errors: 'smartsearch/errors'
    }),
    minDate () {
      return addToDate(date.newDate(), { minutes: 5 })
    },
    minReturnDate () {
      return addToDate(this.parameters.depart, { hours: 4 })
    },
    people: {
      get () {
        return {
          requester: this.parameters.requester,
          traveller: this.parameters.user
        }
      },
      set (val) {
        this.$store.dispatch('smartsearch/setRequester', val.requester)
        this.$store.dispatch('smartsearch/setTraveller', val.traveller)
      }
    },
    returning: {
      get () {
        return !!this.parameters.return
      },
      set (value) {
        this.parameters.return = value
          ? date.addToDate(this.parameters.depart, { hours: 4 })
          : null
      }
    }
  },

  watch: {
    loading (value, old) {
      if (value === true) {
        const partner = store.getters.partner
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: partner.slug,
          spinnerColor: partner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (this.errors) {
          this.$q.dialog({
            title: this.$t('error.search_unsuccessful'),
            message: this.$t('error.try_later'),
            color: 'primary',
            ok: true,
            cancel: this.$t('leave_feedback')
          })
            .onCancel(() => {
              var win = window.open('https://mobilleo.com/resources/support/', '_blank')
              win.focus()
            })
        } else {
          this.$router.push({ name: 'smart-search-results' })
        }
      }
    }
  },
  activated () {
    this.dateClicked = false
  },
  validations: {
    parameters: {
      origin: { required },
      destination: { required },
      user: { required },
      requester: { required }
    }
  },
  methods: {
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    },
    onDateClick () {
      this.dateClicked = !this.dateClicked
    },
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    },
    onSmartSearchFocus (name) {
      if (this.parameters[name]?.label?.toLowerCase() === 'current location') this.parameters[name] = null
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  width 80vw
  max-width 600px
</style>
