<template>
  <q-page class="page layout-padding bg-white column items-center no-wrap">
    <div class="row q-col-gutter-sm">
      <div v-if="sortedContents && sortedContents.length" class="col-12">
        <content-card
          v-for="contentType in sortedContents"
          :key="`smartsearch-contentcard-${contentType}`"
          :type="contentType"
          :result="results[contentType]"
        />
      </div>
      <m-empty-state
        v-else
        style="margin: auto;"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import contentCard from './content-card'

export default {
  name: 'SmartSearchResultsDesktop',
  components: {
    contentCard
  },
  computed: {
    ...mapGetters({
      contents: 'smartsearch/contents',
      results: 'smartsearch/results',
      user: 'user'
    }),
    sortedContents () {
      if (!this.contents || !this.contents.length) return null
      const localContents = [...this.contents]
      return localContents.sort((a, b) => {
        if (this.results[a]) return -1
        if (this.results[b]) return 1
        return 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px
</style>
